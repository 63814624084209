<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <setorizacoes-navigation-tabs
          :selectedTab.sync="selectedTabNavegationTabs"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <setorizacoes-data-table
          ref="setorizacoesDataTable"
          :titulo="tituloTabela"
          :items="items"
          :loading="loading"
          @rechargeTable="chargeTableDecision(tipoListaSetorizacao)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SetorizacoesService from '@/services/SetorizacoesService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    SetorizacoesNavigationTabs: () =>
      import('@/components/setorizacoes/SetorizacoesNavigationTabs'),
    SetorizacoesDataTable: () =>
      import('@/components/setorizacoes/SetorizacoesDataTable')
  },

  data: () => ({
    items: [],
    tituloTabela: 'Setorizações',
    selectedTabNavegationTabs: 0,
    arrTour: [
      {
        element: '#tab-historico',
        intro:
          'Selecionando esta aba você poderá acessar todas as setorizações cadastradas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#tab-favoritos',
        intro:
          'Selecionando esta opção você poderá acessar as setorizações que você definir como suas favoritas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#btn-nova-setorizacao',
        intro:
          'Clicando sobre este botão você poderá criar novas setorizações para serem utilizadas em análises.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#setorizacoes-historico-search',
        intro:
          'Neste campo você poderá pesquisar por setorizações já cadastradas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#algoritmo-nome',
        intro:
          'Aqui você tem acesso ao tipo de setorização que foi criada, ou seja, qual algoritmo foi selecionado para criar a setorização.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#numero-setores',
        intro:
          'Este é o resultado da setorização, mostra o número de setores gerados após o processamento da setorização.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#action-visualizar',
        intro:
          'Nesta opção você poderá visualizar os setores gerados plotados em um mapa.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-favoritar-rel',
        intro:
          'Clicando sobre este botão você poderá marcar esta setorização como favorita, podendo acessá-lo mais rapidamente através do menu superior "FAVORITAS".',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-more-rel',
        intro:
          'Aqui você acessa outras opções para sua setorização, como executá-la novamente, deletá-la e é possivel visualizar informações de criação.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  created() {
    this.chargeTableDecision(this.tipoListaSetorizacao);
  },

  computed: {
    tipoListaSetorizacao() {
      return this.$store.getters.getTipoListaSetorizacao;
    }
  },

  methods: {
    getSetorizacoes() {
      this.items = [];
      this.loading = true;
      SetorizacoesService.index()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    getSetorizacoesFavoritadas() {
      this.items = [];
      this.loading = true;
      SetorizacoesService.getSetorizacoesFavoritadas()
        .then((response) => {
          this.items = response.data.data;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    chargeTableDecision(newDecisionInfo) {
      if (this.$refs.setorizacoesDataTable) {
        this.$refs.setorizacoesDataTable.page = 1;
      }
      this.tituloTabela = newDecisionInfo.titulo;

      switch (newDecisionInfo.tipo) {
        case 'favoritos':
          this.selectedTabNavegationTabs = 1;
          this.cancelRefreshData();
          this.refreshData(this.getSetorizacoesFavoritadas);
          break;
        case 'historico':
        default:
          this.selectedTabNavegationTabs = 0;
          this.cancelRefreshData();
          this.refreshData(this.getSetorizacoes);
          break;
      }
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 4);
      this.iniciarTour(arrTourFormatted);
    }
  },

  watch: {
    tipoListaSetorizacao(newValue) {
      this.chargeTableDecision(newValue);
    }
  }
};
</script>

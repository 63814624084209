import APIService from './APIService';

export default {
  index() {
    return APIService.apiCall().get('/setorizacoes/index');
  },
  getSetorizacoesFavoritadas() {
    return APIService.apiCall().get(
      '/setorizacoes/get-setorizacoes-favoritadas'
    );
  },
  getSetorizacao(id) {
    return APIService.apiCall().get(`/setorizacoes/get-setorizacao/${id}`);
  },
  getSetores(id) {
    return APIService.apiCall().get(`/setorizacoes/get-setores/${id}`);
  },
  save(postData) {
    return APIService.apiCall().post(
      '/setorizacoes/save',
      JSON.stringify(postData)
    );
  },
  deletar(id) {
    return APIService.apiCall().get(`/setorizacoes/excluir/${id}`);
  },
  executar(id) {
    return APIService.apiCall().get(`/setorizacoes/executar/${id}`);
  },
  favoritar(id) {
    return APIService.apiCall().get(`/setorizacoes/favoritar/${id}`);
  },
  getSetorizacoesByVersaoBdgd(mesDadosBdgd, versaoBdgd, status = 'SUCESSO') {
    return APIService.apiCall().get(
      `/setorizacoes/get-setorizacoes-by-versao-bdgd/${mesDadosBdgd}/${versaoBdgd}/${status}`
    );
  }
};
